import { UserStats } from "../models/UserStats";
import api from "./config/apiConfig";

export type User = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    picture: string;
    nickname: string;
};

export const updateUser = async (user: User): Promise<User>  => {
    try {
        const response = await api.post(`/users/${user.id}`, user);
        if (response.status === 200) {
            return response.data.user;
        }
        else {
            throw new Error("Update user failed");
        }
    }
    catch (error) {
        console.error("Update user failed:", error);
        return Promise.reject(error);
    }
}

export const resetPassword = async (token: string, newPassword: string) => {
    try {
      const encodedPassword = btoa(newPassword); // Encode password using Base64
      const response = await api.post(`/users/reset-password`, {
        token,
        newPassword: encodedPassword,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Reset password failed");
      }
    } catch (error) {
        console.error("Reset failed:", error);
        throw error;
      }
};


export const getUserStats = async (
  userId: string
): Promise<UserStats> => {
  try {
    const response = await api.get(`/users/${userId}/stats`);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get user videos ready to battle failed");
    }
  } catch (error) {
    console.error("Get user videos ready to battle failed:", error);
    throw error;
  }
};
