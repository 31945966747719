import api from "./config/apiConfig";

export const challengeToBattle = async (
  accessToken: string,
  startingOpponentVideoId: number,
  opponentVideoId: number
): Promise<void> => {
  try {
    const response = await api.post(
      `/challenges/create`,
      {
        startingOpponentVideoId: startingOpponentVideoId,
        opponentVideoId: opponentVideoId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Challenge to battle failed");
    }
  } catch (error) {
    console.error("Challenge to battle failed:", error);
    throw error;
  }
};

export const acceptChallenge = async (
  accessToken: string,
  battleId: number
): Promise<void> => {
  try {
    const response = await api.post(
      `/challenges/accept`,
      { battleId: battleId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      console.error(
        `Accept challenge failed for battle ${battleId}, status: ${response.status}`
      );
    }
  } catch (error) {
    console.error(`Accept challenge failed for battle ${battleId}:`, error);
  }
};

export const cancelChallenge = async (
  accessToken: string,
  battleId: number
): Promise<void> => {
  try {
    const response = await api.post(
      `/challenges/cancel`,
      { battleId: battleId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Cancel challenge failed");
    }
  } catch (error) {
    console.error("Cancel challenge failed:", error);
  }
};

export const getMyChallengesSent = async (
  accessToken: string
): Promise<any[]> => {
  try {
    const response = await api.get(`/challenges/sent`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get my challenges sent failed");
    }
  } catch (error) {
    console.error("Get my challenges sent failed:", error);
    throw error;
  }
};

export const getMyChallengesReceived = async (
  accessToken: string
): Promise<any[]> => {
  try {
    const response = await api.get(`/challenges/received`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get my challenges received failed");
    }
  } catch (error) {
    console.error("Get my challenges received failed:", error);
    throw error;
  }
};
