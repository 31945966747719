import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
}

export const NavLink = ({ to, children, className = "" }: NavLinkProps) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const navigate = useNavigate();

  // Solution to avoid a-hrefs (page refresh and blinking items) and also hide the offcanvas with data-bs-dismiss
  // Link to is not working with data-bs-dismiss
  const handleLinkClick = (path: string) => {
    navigate(path);
  };

  return (
    <Link
      to={to}
      onClick={() => handleLinkClick(to)}
      className={`${className} ${isActive ? "selected" : ""} `}
      style={{ textDecoration: "none", color: "inherit" }}
      data-bs-dismiss="offcanvas"
    >
      {children}
    </Link>
  );
};