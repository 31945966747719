import api from "./config/apiConfig"
import { UserVideoForFeedback } from "../models/UserVideoForFeedback";
import { Point } from "../models/PointCategory";

export const getVideosForFeedback = async (): Promise<
  UserVideoForFeedback[]
> => {
  try {
    const response = await api.get("/admin/videos/pending", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Get videos for feedback failed:", response);
      return [];
    }
  } catch (error) {
    console.error("Get videos for feedback failed:", error);
    throw error;
  }
};

export const submitFeedback = async (
  videoId: string,
  points: Point[]
): Promise<void> => {
  try {
    const response = await api.post("/admin/feedback", {
      videoId: videoId,
      points: points,
    });
    if (response.status !== 200) {
      throw new Error("Submit feedback failed");
    }
  } catch (error) {
    console.error("Submit feedback failed:", error);
    throw error;
  }
};
