import React from "react";
import { OpponentVideo } from "../../models/OpponentVideo";
import { getUserName } from "../../utility/TextFormatter";
import { Sword, Trophy, Heart, Dot, PaperPlaneRight } from "@phosphor-icons/react";

type OpponentCardProps = {
  opponentVideo: OpponentVideo;
  buttonDisabled: boolean;
  onChallengeToBattleClick: (
    opponentVideoId: number,
    opponentName: string
  ) => void;
};

const OpponentCard: React.FC<OpponentCardProps> = ({
  opponentVideo,
  buttonDisabled,
  onChallengeToBattleClick,
}) => {
  const userName = getUserName(opponentVideo.user);

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div className="rounded-4 bg-dark-light p-3">
        <div className="d-flex align-items-center gap-3">
          <div>
            <img
              width="40"
              height="40"
              className="rounded-3 img-fluid"
              crossOrigin="anonymous"
              alt={opponentVideo.user?.firstName || "User profile picture"}
              src={
                opponentVideo.user?.pictureUrl || "/profile_placeholder.png"
              }
            />
          </div>
          <div>
            <h3 className="mb-1 fs-6 fw-medium">
              <small>
                {userName}
              </small>
            </h3>
            <div className="user-info d-flex align-items-center gap-2 flex-wrap">
              <div className="d-flex align-items-center gap-1">
                <div>
                  <img src="/images/badges/badge-axe-boss.png" alt="" />
                  <img src="/images/badges/badge-axe-boss.png" alt="" />
                  <img src="/images/badges/badge-axe-boss.png" alt="" />
                </div>
                <span>
                  <small className="d-flex align-items-center text-secondary">
                    +6
                  </small>
                </span>
              </div>
              <span>
                <small className="d-flex align-items-center text-yellow gap-1">
                  <Sword size={18} /> 128 / 839
                </small>
              </span>
              <span>
                <small className="d-flex align-items-center text-yellow gap-1">
                  <Trophy size={18} /> 29 / 756
                </small>
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-1">
          <p className="m-0 fw-semibold text-truncate">
            <small>{opponentVideo.song?.artistName || " "}</small>
          </p>
          <Dot size={12} />
          <p className="m-0 text-secondary text-truncate">
            <small>{opponentVideo.song?.songTitle || " "}</small>
          </p>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <button
            className="bg-transparent border-0 text-yellow fs-6 p-0"
            onClick={() =>
              onChallengeToBattleClick(
                opponentVideo.id,
                getUserName(opponentVideo.user)
              )
            }
            disabled={buttonDisabled}
          >
            <small className="d-flex align-items-center gap-2">Send challenge<PaperPlaneRight size={20} /></small>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OpponentCard;
