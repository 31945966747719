import axios, { AxiosInstance, AxiosError } from "axios";
import { useAuth, AuthContextProps } from "../../providers/AuthProvider";
import axiosRetry from "axios-retry";
import { useState, useEffect } from "react";

export const apiBaseUrl = process.env.REACT_APP_API_URL;

const api: AxiosInstance = axios.create({
  baseURL: apiBaseUrl,
  timeout: 30000, // Timeout in milliseconds
});

axiosRetry(api, { retries: 3 }); // Retry the request up to 3 times

export const useApiInterceptor = (authProps: AuthContextProps) => {
  const { authState, logout } = authProps;
  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    const requestInterceptor = api.interceptors.request.use((config) => {
      const accessToken = authState?.tokens?.access_token;
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });

    const responseInterceptor = api.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          await logout();
        } else {
          setNetworkError(true);
        }
        return Promise.reject(error);
      }
    );

    // Cleanup interceptors when component unmounts or when authState changes
    return () => {
      api.interceptors.request.eject(requestInterceptor);
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [authState?.tokens?.access_token, logout]); // Re-run effect when accessToken changes
};

export default api;
