import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Notification } from "../../models/Notification";
import { Helmet } from "react-helmet-async";
import { ArrowCircleLeft } from "@phosphor-icons/react";

const NotificationDetailPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const notification = location.state?.notification as Notification; // Access notification from location state

  if (!notification) {
    return <p>Notification not found!</p>; // If there's no notification, show a message
  }

  // Hardcoded HTML content
  const hardCodedHTML = `

                <table class="container" width="600" cellpadding="0" cellspacing="0" border="0">
                    <!-- Content -->
                    <tr class="content" bgcolor="#232323">
                        <td style="padding: 32px;">
                            <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                <tr>
                                    <td>Your rival has accepted your challenge!</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 24px;padding-bottom:32px;">It's officially game on! 🎶</td>
                                </tr>
                                <tr>
                                    <td style="padding-bottom:16px;">But wait —
                                        <div style="font-weight:bold;display:inline-block;">want to make sure you win?
                                    </td>
                                </tr>
                                <tr>
                                    <td>Share the battle on your social networks and let your friends join in on the
                                        fun. More votes, more support, and more bragging rights when you crush your
                                        opponent!</td>
                                </tr>
                                <tr>
                                    <td style="padding-top:24px;padding-bottom:24px;">
                                        <a href="https://test.guitarbattleclub.com/battles/176"
                                            style="padding:12px 24px;background:#000;color:#FEE034;border-radius:12px;text-decoration:none;">
                                            Share the battle <div style="display:inline-block;color:#fff;"></div>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                  <td>
                                    and get those votes rolling!
                                  </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                </table>
  `;

  return (
    <>
      <Helmet>
        <title>{notification.title}</title>
      </Helmet>
      {/* <div className="p-4 px-md-5 w-100 border-bottom">
        <h2 className="fw-semibold fs-1">Notifications</h2>
        <p className="m-0 text-secondary">Notifications will be automatically deleted after reading them.</p>
      </div> */}
      <div className="p-4 px-md-5 w-100">
        <div className="d-flex justify-content-between align-items-center">
          <button onClick={() => navigate(-1)} className="bg-transparent border-0 rounded-0 px-0 text-yellow">
            <small className="d-flex gap-2 align-items-center">
              <ArrowCircleLeft size={24} />
              Back
            </small>
          </button>
          <span>
            <small className="text-secondary">
              {new Date(notification.createdAt).toLocaleString()}
            </small>
          </span>
        </div>
        <h3 className="card-title m-0">{notification.title}</h3>
        <div
          className="bg-dark-light rounded-4 overflow-hidden mt-4"
          dangerouslySetInnerHTML={{ __html: hardCodedHTML }}
        ></div>
      </div>
    </>
  );
};

export default NotificationDetailPage;
