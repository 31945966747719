import { FunctionComponent, useMemo, type CSSProperties } from "react";
import { formatDate } from "../../../utility/DateFormatter";
import { Battle } from "../../../models/Battle"
import { User } from "../../../models/User";
import PlayerSongCard, { PlayerStatus } from "./PlayerSongCard";
import SocialShare from './SocialShare';
import { ArrowCircleRight, ShareNetwork } from "@phosphor-icons/react";
import { checkIsBattleLikedByMe, generateSocialShareTitle } from "../utility/BattlesUtility";
import OffcanvasButton from "../../components/OffcanvasButton";
import Offcanvas from "../../components/Offcanvas";

const url = `${window.location.origin}${location.pathname}`;

export type BattleCardProps = {
  battle: Battle;
  type: string;
  onBattleClick: (battle: Battle) => void;
  currentUserId: string | undefined; // Accept currentUserId as a prop
};

const BattleCard: FunctionComponent<BattleCardProps> = ({
  battle,
  type,
  onBattleClick,
  currentUserId, // Destructure currentUserId
}) => {

  const getStartingPlayerStatus = (battle: Battle): PlayerStatus => {
    if (battle.startingOpponent.likes > battle.opponent.likes) {
      return PlayerStatus.WINNER;
    } else if (battle.startingOpponent.likes < battle.opponent.likes) {
      return PlayerStatus.LOSER;
    } else {
      return PlayerStatus.TIE;
    }
  };

  const getOpponentPlayerStatus = (battle: Battle): PlayerStatus => {
    if (battle.opponent.likes > battle.startingOpponent.likes) {
      return PlayerStatus.WINNER;
    } else if (battle.opponent.likes < battle.startingOpponent.likes) {
      return PlayerStatus.LOSER;
    } else {
      return PlayerStatus.TIE;
    }
  };

  const isUserInBattle = currentUserId !== undefined &&
    (Number(currentUserId) === battle.startingOpponent?.user.id ||
      Number(currentUserId) === battle.opponent?.user.id);

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div className="bg-dark-light rounded-4 p-3 h-100">
        <span className="m-0 text-secondary">
          <small>
            {type === "FINISHED"
              ? `Ended at ${formatDate(new Date(battle.endedAt))}`
              : `Ends ${formatDate(new Date(battle.endedAt))}`}
          </small>
        </span>
        <div className="pt-3 d-flex flex-column gap-3">
          <PlayerSongCard
            user={battle.startingOpponent?.user}
            userStats={battle.startingOpponent?.userStats}
            likes={battle.startingOpponent?.likes || 0}
            playerStatus={getStartingPlayerStatus(battle)}
            video={battle.startingOpponent?.video}
            tabType={type}
          />
          <PlayerSongCard
            user={battle.opponent?.user}
            userStats={battle.opponent?.userStats}
            likes={battle.opponent?.likes || 0}
            playerStatus={getOpponentPlayerStatus(battle)}
            video={battle.opponent?.video}
            tabType={type}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-4">
          <OffcanvasButton
            targetId="socialShare"
            label={<small>Share battle</small>}
            icon={<ShareNetwork size={20} />}
            className="bg-transparent p-0 border-0 text-yellow"
          />
          <Offcanvas id="socialShare">
            <SocialShare url={url} title={generateSocialShareTitle(battle)} />
          </Offcanvas>
          <button
            onClick={() => onBattleClick(battle)}
            role="button"
            className="bg-transparent border-0 text-yellow fs-6 p-0"
          >
            <small>
              {type === "FINISHED" || checkIsBattleLikedByMe(battle.id)
                ? "View"
                : "View & vote"}{" "}
              <ArrowCircleRight size={24} />
            </small>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BattleCard;
