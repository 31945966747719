import React from "react";
import { IconProps } from "@phosphor-icons/react";
import { NavLink } from "../NavLink";

interface MenuItemProps {
  text: string;
  icon: React.ReactElement<IconProps>;
  borderTop?: boolean;
  linkTo?: string;
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  text,
  icon,
  borderTop,
  linkTo,
  onClick,
}) => {
  return onClick ? (
    <li>
      <a
        onClick={onClick}
        href=""
        className={`${
          borderTop ? "border-top" : ""
        } d-flex justify-content-end gap-3 px-4 py-3 px-md-5 py-md-4 text-white text-decoration-none`}
      >
        {text} {icon}
      </a>
    </li>
  ) : (
    <li>
      <NavLink
        to={linkTo || "/home"} // Default to "/home" if linkTo is not provided
        className={`${
          borderTop ? "border-top" : ""
        } d-flex justify-content-end gap-3 px-4 py-3 px-md-5 py-md-4 text-white text-decoration-none`}
        data-bs-dismiss="offcanvas"
      >
        {text} {icon}
      </NavLink>
    </li>
  );
};

export default MenuItem;
