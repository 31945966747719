import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Add useLocation
import BattleTabs from "./components/BattleTabs";
import BattleCard from "./components/BattleCard";
import { getAllBattles } from "../../api/battles";
import { Battle } from "../../models/Battle";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../../providers/AuthProvider";
import FindOpponent from "../find_opponent/FindOpponent";

const Battles: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const { authState } = useAuth();
  const userId = authState.user?.id;

  const [battlesActive, setBattlesActive] = useState<Battle[]>([]);
  const [battlesFinished, setBattlesFinished] = useState<Battle[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  // Determine the selected tab based on the URL path
  const selectedTab = location.pathname.includes("active")
    ? "ACTIVE"
    : location.pathname.includes("finished")
      ? "FINISHED"
      : location.pathname.includes("find-opponents")
        ? "FIND_OPPONENTS"
        : "ACTIVE";

  const fetchBattles = async () => {
    try {
      setLoading(true);
      if (selectedTab === "ACTIVE") {
        const activeBattles: Battle[] = await getAllBattles("active");
        setBattlesActive(activeBattles);
      } else if (selectedTab === "FINISHED") {
        const finishedBattles: Battle[] = await getAllBattles("finished");
        setBattlesFinished(finishedBattles);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(
        error instanceof Error
          ? error
          : new Error("Failed to fetch all battles")
      );
    }
  };

  useEffect(() => {
    fetchBattles();
  }, [selectedTab]);

  // Handle tab change and update URL path
  const handleTabChange = (tab: string) => {
    if (tab === "ACTIVE") {
      navigate("/battles/active");
    } else if (tab === "FINISHED") {
      navigate("/battles/finished");
    } else if (tab === "FIND_OPPONENTS") {
      navigate("/battles/find-opponents");
    }
  };

  const onActiveBattleClick = (battle: Battle) => {
    navigate(`/battles/${battle.id}`);
  };

  return (
    <>
      <Helmet>
        <title>{"Battles"}</title>
      </Helmet>
      <div className="p-4 px-md-5 w-100 pb-0">
        <h2 className="fw-semibold fs-1">Battle Arena</h2>
        <p className="m-0 text-secondary">Challenge others to guitar battles.Invite your friends to vote for you to win the battle and earn rewards.</p>
      </div>
      <div className="w-100 p-4 px-md-5 border-bottom">
        <BattleTabs onTabChange={handleTabChange} selectedTab={selectedTab} />
      </div>
      <div className="p-4 px-md-5 w-100">
        {loading && (
          <div className="p-4 px-md-5 text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {!loading &&
          selectedTab === "ACTIVE" &&
          battlesActive.length === 0 && (
            <div className="p-4 px-md-5 text-center">
              <p>No active battles yet.</p>
            </div>
          )}
        {!loading &&
          selectedTab === "FINISHED" &&
          battlesFinished.length === 0 && (
            <div className="p-4 px-md-5 text-center">
              <p>No finished battles yet.</p>
            </div>
          )}
        <div className="row">
          {selectedTab === "ACTIVE" &&
            battlesActive.map((activeBattle) => (
              <BattleCard
                key={activeBattle.id}
                battle={activeBattle}
                type="ACTIVE"
                onBattleClick={onActiveBattleClick}
                currentUserId={userId}
              />
            ))}
          {selectedTab === "FINISHED" &&
            battlesFinished.map((finishedBattle) => (
              <BattleCard
                key={finishedBattle.id}
                battle={finishedBattle}
                type="FINISHED"
                onBattleClick={onActiveBattleClick}
                currentUserId={userId}
              />
            ))}
        </div>
        {selectedTab === "FIND_OPPONENTS" && <FindOpponent />}
      </div>
    </>
  );
};

export default Battles;
