import React from 'react';

interface OffcanvasButtonProps {
    targetId: string;
    label?: React.ReactNode; // Accept any JSX as the label
    icon?: React.ReactNode;
    className?: string;
}

const OffcanvasButton: React.FC<OffcanvasButtonProps> = ({
    targetId,
    label,
    icon,
    className = 'btn btn-primary', // Default className
}) => {
    return (
        <button
            className={className}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target={`#${targetId}`}
            aria-controls={targetId}
        >
            {label && label}
            {icon && <span className="ms-2">{icon}</span>}
        </button>
    );
};

export default OffcanvasButton;
