import React from 'react';

interface AccordionItem {
    id: number;
    title: string;
    content: JSX.Element;  // Change content type from string to JSX.Element
}

interface AccordionProps {
    items: AccordionItem[];
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
    return (
        <div className="accordion overflow-hidden border-bottom mb-4 w-100" id="accordionTools">
            {items.map((item, index) => (
                <div className="accordion-item" key={item.id}>
                    <div className="accordion-header" id={`heading${item.id}`}>
                        <button
                            className={`px-4 px-md-5 accordion-button fs-6 fw-semibold ${index !== 0 ? 'collapsed' : ''}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${item.id}`}
                            aria-expanded={index === 0 ? 'true' : 'false'}
                            aria-controls={`collapse${item.id}`}
                        >
                            {item.title}
                        </button>
                    </div>
                    <div
                        id={`collapse${item.id}`}
                        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                        aria-labelledby={`heading${item.id}`}
                        data-bs-parent="#accordionTools"
                    >
                        <div className="accordion-body px-4 px-md-5">
                            {item.content}  {/* This will render the JSX content */}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Accordion;
