import React, { useState, ChangeEvent, FormEvent } from "react";
import EmailInput from "./EmailInput";
import PasswordInput from "./PasswordInput";

interface SignInWithEmailProps {
  continue: (data: { email: string; password: string }) => void;
  onForgotPasswordClick: () => void;
  clearErrorMessage: () => void;
}

const SignInWithEmailForm: React.FC<SignInWithEmailProps> = (props) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.continue({ email, password });
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    props.clearErrorMessage();
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    props.clearErrorMessage();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {/* Email input */}
        <div className="col-12 col-md-12">
          <EmailInput value={email} onChange={handleEmailChange} />
        </div>
        {/* Password input */}
        <div className="col-12 col-md-12">
          <PasswordInput
            name="Password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
      </div>
      <div className="row align-items-center justify-content-center">
        {/* Forgot password */}
        <div className="col-12">
          <button
            type="button"
            className="text-yellow w-100 text-decoration-none fw-normal text-center bg-transparent border-0 mt-2 mx-0 px-0"
            onClick={props.onForgotPasswordClick}
          >
            <small>Forgot your password?</small>
          </button>
        </div>
        {/* Log in now */}
        <div className="col-12 col-md-12 d-flex align-items-center">
          <button type="submit" className="btn btn-warning rounded-pill w-auto mx-auto my-4 px-3 py-2">
            Log in now
          </button>
        </div>
      </div>
    </form>
  );
};

export default SignInWithEmailForm;
