import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { useAuth } from "../../providers/AuthProvider";
import UserMenu from "./UserMenu";
import QuickMenu from "./QuickMenu";

export const MainLayout = () => {
  const { authState } = useAuth();

  return (
    <div>
      <Header />
      {authState.isAuthenticated && <UserMenu />}
      <main className="d-flex flex-wrap w-100">
        <Outlet />
      </main>
      <QuickMenu />
      <Footer />
    </div>
  );
};
