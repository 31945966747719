import { FunctionComponent, useCallback, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { Sword, Guitar, Person, BoxArrowUp, LockOpen } from "@phosphor-icons/react";

const QuickMenu: FunctionComponent = () => {
    const { authState } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    // State to track the selected menu item
    const [selected, setSelected] = useState<string>("");

    // Synchronize selected state with location.pathname
    useEffect(() => {
        switch (location.pathname) {
            case "/learn-song-list":
                setSelected("Lessons");
                break;
            case "/battles":
                setSelected("Battle Arena");
                break;
            case "/sign-in":
                setSelected("Log In");
                break;
            case "/my-dashboard":
                setSelected("My Dashboard");
                break;
            case "/my-videos":
                setSelected("Upload Video");
                break;
            case "/find-opponent":
                setSelected("Find Opponent");
                break;
            default:
                setSelected(""); // Reset for other routes, including "/"
                break;
        }
    }, [location.pathname]);

    /** Action props */
    const onLearnSongsTextClick = useCallback(() => navigate("/learn-song-list"), [navigate]);
    const onBattlesTextClick = useCallback(() => navigate("/battles"), [navigate]);
    const onLogInTextClick = useCallback(() => navigate("/sign-in"), [navigate]);
    const onMyDashboardClick = useCallback(() => navigate("/my-dashboard"), [navigate]);
    const onMyVideosClick = useCallback(() => navigate("/my-videos"), [navigate]);
    const onFindOpponentClick = useCallback(() => navigate("/find-opponent"), [navigate]);

    return (
        <div className="quickmenu">
            <div className="d-flex px-2 py-2 py-md-3">
                <div className="flex-grow-1 w-100 text-center">
                    <button
                        className={`border-0 bg-transparent p-0 ${selected === "Battle Arena" ? "selected" : ""}`}
                        onClick={onBattlesTextClick}
                    >
                        <Sword size={24} />
                        <p className="m-0 mt-1"><small>Battle Arena</small></p>
                    </button>
                </div>
                <div className="flex-grow-1 w-100 text-center">
                    <button
                        className={`border-0 bg-transparent p-0 ${selected === "Lessons" ? "selected" : ""}`}
                        onClick={onLearnSongsTextClick}
                    >
                        <Guitar size={24} />
                        <p className="m-0 mt-1"><small>Lessons</small></p>
                    </button>
                </div>
                {authState.isAuthenticated ? (
                    <>
                        <div className="flex-grow-1 w-100 text-center">
                            <button
                                className={`border-0 bg-transparent p-0 ${selected === "Find Opponent" ? "selected" : ""}`}
                                onClick={onFindOpponentClick}
                            >
                                <Person size={24} />
                                <p className="m-0 mt-1"><small>Find Opponent</small></p>
                            </button>
                        </div>
                        <div className="flex-grow-1 w-100 text-center">
                            <button
                                className={`border-0 bg-transparent p-0 ${selected === "Upload Video" ? "selected" : ""}`}
                                onClick={onMyVideosClick}
                            >
                                <BoxArrowUp size={24} />
                                <p className="m-0 mt-1"><small>Upload Video</small></p>
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="flex-grow-1 w-100 text-center">
                        <button
                            className={`border-0 bg-transparent p-0 ${selected === "Log In" ? "selected" : ""}`}
                            onClick={onLogInTextClick}
                        >
                            <LockOpen size={24} />
                            <p className="m-0 mt-1"><small>Log In</small></p>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuickMenu;
