import React from "react";
import { Badge } from "../../../models/Badge";
import { getBadgeImage } from "../../../utility/BadgeUtility";

interface UserBadgesSmallDisplayProps {
  badges?: Badge[];
}

const UserBadgesSmallDisplay: React.FC<UserBadgesSmallDisplayProps> = ({
  badges = [],
}) => {
  if (badges.length === 0) return null; // Don't render if no badges

  const displayedBadges = badges.slice(0, 3); // Show up to 3 badges
  const additionalBadgeCount = badges.length - 3; // Calculate the "+X" count

  return (
    <div className="d-flex align-items-center gap-1">
      <div>
        {displayedBadges.map((badge, index) => (
          <img
            key={index} // Unique key for React's list rendering
            src={getBadgeImage(badge.name)}
            alt={badge.name} // Use badge name as alt text for accessibility
            className="badge-image"
          />
        ))}
      </div>
      {additionalBadgeCount > 0 && (
        <span>
          <small className="d-flex align-items-center text-secondary">
            +{additionalBadgeCount}
          </small>
        </span>
      )}
    </div>
  );
};

export default UserBadgesSmallDisplay;
