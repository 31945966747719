import {
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import { useAuth } from "../../providers/AuthProvider";
import { submitFeedback } from "../../api/admin";
import { Point } from "../../models/PointCategory";

const AdminVideoFeedback: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useAuth();
  const isAdmin = authState.user?.isAdmin;
  const { userVideo } = location.state;
  const [points, setPoints] = useState<Point[]>(userVideo?.points || []);

  // console.log(userVideo);

  useEffect(() => {
    if (isAdmin === false) {
      navigate("/home");
    }
  }, [isAdmin, navigate]);

  function onBackClick(): void {
    navigate("/admin");
  }

  const sendFeedback = useCallback(() => {
    // Send feedback to the server
    submitFeedback(userVideo?.id, points).then(() => {
      navigate("/admin");
    });
  }, [userVideo?.id, 0, navigate]);

  return (
    <div>
      <div className="bg-yellow py-5">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">Video feedback</h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  Upload state: {userVideo?.uploadState}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green border-top ">
        <div className="container-gbc py-4 py-md-5">
          <div className="d-flex flex-column align-items-center">
            <div className="col-12 col-md-6 col-lg-6 mb-4">
              <div className="card-songs d-flex flex-column h-100">
                <AWSVideoPlayerForm
                  videoUrl={userVideo?.videoUrl}
                  thumbnailUrl={userVideo?.thumbnailUrl}
                  isPrivate={true}
                />
                <div className="song-info px-3 d-flex  gap-3 px-md-4 py-3">
                  <img
                    width="44"
                    height="44"
                    className="rounded-circle"
                    crossOrigin="anonymous"
                    alt=""
                    src={userVideo?.userPicture || "/profile_placeholder.png"}
                  />
                  <div className="d-flex flex-column w-100">
                    <div className="d-flex justify-content-between">
                      <h4 className="fs-4 fw-bold m-0">
                        {userVideo?.userFirstName} {userVideo?.userLastName}
                      </h4>
                      <div className="text-capitalize bg-white rounded-pill px-3 py-2 d-inline-flex bg-opacity-50">
                        <small>{userVideo?.song?.songLevel || " "} / {userVideo?.song?.subLevel || " "}</small>
                      </div>
                    </div>
                    <div className="d-flex gap-2 align-items-center flex-wrap">
                      <p className="m-0 fw-bold">
                        {userVideo?.song?.artistName || " "}
                      </p>
                      <p className="m-0">-</p>
                      <p className="m-0 fw-normal">
                        {userVideo?.song?.songTitle || ""}
                      </p>
                    </div>
                  </div>
                </div>
                {points.map((point: Point) => {
                  return (
                    <div className="p-3 p-md-4 d-flex flex-column" key={point.id}>
                      <div className="d-flex align-items-center justify-content-between gap-4 mb-2">
                        <label
                          htmlFor="lesson-feedback"
                          className="form-label mb-0"
                        >
                          {point.name}
                        </label>
                        <div className="text-capitalize rounded-pill border border-1 px-3 py-2 d-inline-flex w-25 text-center">
                          {point.points}
                        </div>
                      </div>
                      <input
                        type="range"
                        className="form-range"
                        min={0}
                        max={point.max_points}
                        step={1}
                        id={`lesson-feedback-${point.id}`}
                        value={point.points}
                        onChange={(event) => {
                          console.log(event.target.value);
                          point.points = parseInt(event.target.value);
                          setPoints([...points]);
                        }
                        }
                      />
                    </div>
                  );
                }
                )}
                <div className="p-3 p-md-4 border-top border-black">
                  <button
                    onClick={sendFeedback}
                    className="bttn align-self-start"
                  >
                    Submit feedback
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminVideoFeedback;
