import React, { useState, useEffect } from "react";
import {
  getNotificationsByUserId,
  markNotificationAsRead,
} from "../../api/notifications";
import { useAuth } from "../../providers/AuthProvider";
import NotificationCard from "./NotificationCard";
import { Notification } from "../../models/Notification";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const NotificationsPage: React.FC = () => {
  const { authState } = useAuth();
  const { tokens } = authState;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    fetchNotifications();
  }, [tokens?.access_token]);

  const fetchNotifications = async () => {
    try {
      const accessToken = tokens?.access_token || "";
      const notifications: Notification[] = await getNotificationsByUserId(
        accessToken
      );
      setNotifications(notifications);
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    }
  };

  const markAsRead = async (id: number) => {
    try {
      const accessToken = tokens?.access_token || "";
      await markNotificationAsRead(accessToken, id);

      // Update the state locally to reflect the read status
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === id
            ? { ...notification, isRead: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };


  const handleNavigateToDetail = (notification: Notification) => {
    navigate(`/notifications/${notification.id}`, {
      state: { notification }, // Passing the notification as state
    });
  };

  return (
    <>
      <Helmet>
        <title>{"Notifications"}</title>
      </Helmet>
      <div className="p-4 px-md-5 w-100 border-bottom">
        <h2 className="fw-semibold fs-1">Notifications</h2>
        <p className="m-0 text-secondary">Notifications will be automatically deleted after reading them.</p>
      </div>
      <div className="p-4 px-md-5">
        {notifications.length === 0 ? (
          <p>No notifications available.</p>
        ) : (
          <div className="row">
            {notifications.map((notification) => (
              <div className="col-12 col-md-6 col-lg-4 mb-4" key={notification.id}>
                <NotificationCard
                  notification={notification}
                  onMarkAsRead={markAsRead}
                  onClick={() => handleNavigateToDetail(notification)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationsPage;
