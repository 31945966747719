import React from 'react';
import Profile from './Profile';
import { Helmet } from "react-helmet-async";

const MyProfilePage: React.FC = () => {
  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"My profile"}</title>
      </Helmet>
      <div className="p-4 px-md-5 w-100 pb-0">
        <h2 className="fw-semibold fs-1">Your Profile</h2>
        <p className="m-0 text-secondary">Manage your informations.</p>
      </div>
      <div className="p-4 px-md-5">
        <Profile />
      </div>
    </>
  );
};

export default MyProfilePage;