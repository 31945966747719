export const getBadgeImage = (badgeName: string): string => {
  const badgeImageMap: Record<string, string> = {
    "GBC Member": "/images/badges/badge-axe-boss.png",
    "Rising Star": "/images/badges/badge-battle-striker.png",
    "Bronze Guitarist": "/images/badges/badge-bronze-bender.png",
    "Silver Guitarist": "/images/badges/badge-fretboard-freshman.png",
    "Gold Guitarist": "/images/badges/badge-golden-groover.png",
    "Guitar Battle Hero": "/images/badges/badge-jam-slam.png",
    "Battle Winner": "/images/badges/badge-silver-shredder.png",
  };

  return badgeImageMap[badgeName] || "/images/badges/badge-axe-boss.png";
};
