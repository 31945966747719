import React from "react";
import { Challenge } from "../../models/Challenge";
import PlayerSongCard from "../battles/components/PlayerSongCard";
import { CheckCircle, XCircle } from "@phosphor-icons/react";

interface ChallengeCardProps {
  challenge: Challenge;
  type: "SENT" | "RECEIVED";
  onCancelChallengeClick: (battleId: number) => void;
  onAcceptChallengeClick: (battleId: number) => void;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({
  challenge,
  type,
  onCancelChallengeClick,
  onAcceptChallengeClick,
}) => {
  if (!challenge) return null;

  const isExpired = challenge.status === "expired";
  const cardStyle = isExpired ? { opacity: 0.7 } : {};

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div className="bg-dark-light rounded-4 p-3 h-100">
        <span className="m-0 text-secondary">
          <small>
            {/* {type === "FINISHED" ? `Ended at ${formatDate(new Date(battle.endedAt))}` : `Ends ${formatDate(new Date(battle.endedAt))}`} */}
          </small>
        </span>
        <div className="pt-3 d-flex flex-column gap-3">
          <PlayerSongCard
            user={challenge.userData?.user}
            userStats={challenge.userData?.userStats}
            likes={-1}
            playerStatus={undefined}
            video={challenge.userData?.video}
            tabType={"CHALLENGE"}
          />
          <PlayerSongCard
            user={challenge.opponentData?.user}
            userStats={challenge.userData?.userStats}
            likes={-1}
            playerStatus={undefined}
            video={challenge.opponentData?.video}
            tabType={"CHALLENGE"}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-4">
          {type === "SENT" && (
            <div className="d-flex justify-content-end w-100">
              <button
                onClick={() => onCancelChallengeClick(challenge.id)}
                className="bg-transparent border-0 text-yellow fs-6 p-0"
                disabled={isExpired}
              >
                <small>
                  Cancel challenge <XCircle size={24} />
                </small>
              </button>
            </div>
          )}
          {type === "RECEIVED" && (
            <div className="d-flex justify-content-between w-100">
              <button
                onClick={() =>
                  onCancelChallengeClick(challenge.id)
                } /*check if this is ok @kinga*/
                className="bg-transparent border-0 text-secondary fs-6 p-0"
                disabled={isExpired}
              >
                <small>
                  Reject challenge <XCircle size={24} />
                </small>
              </button>
              <button
                onClick={() => onAcceptChallengeClick(challenge.id)}
                className="bg-transparent border-0 text-yellow fs-6 p-0"
                disabled={isExpired}
              >
                <small>
                  Accept challenge <CheckCircle size={24} />
                </small>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChallengeCard;
