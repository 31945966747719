import { FunctionComponent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { signUpWithEmail } from "../../api/signup";
import { loginGoogle } from "../../api/login";
import { useAuth } from "../../providers/AuthProvider";
import SignUpWithEmailForm from "./SignUpWithEmailForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

interface SignUpStep1Props {
  continue: () => void;
}

const SignUpStep1: FunctionComponent<SignUpStep1Props> = (props) => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const onLogInTextClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  const onClickSignUpFacebook = async () => { };

  const onClickSignUpGoogle = useGoogleLogin({
    onSuccess: async (codeResponse: any) => {
      try {
        const { accessToken, refreshToken, user, lessons } = await loginGoogle(
          codeResponse.code
        );
        if (accessToken && user) {
          login(user, {
            access_token: accessToken,
            refresh_token: refreshToken,
          });
          if (lessons && lessons.length > 0) {
            navigate("/my-lessons");
          } else {
            navigate("/learn-song-list");
          }
        } else {
          console.error(
            "Google sign in failed: Access token or user not available"
          );
        }
      } catch (error) {
        console.error("Google sign up failed:", error);
      }
    },
    flow: "auth-code",
  });

  const onClickSignUpWithEmail = async (data: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => {
    try {
      const { accessToken, user } = await signUpWithEmail(
        data.firstName,
        data.lastName,
        data.email,
        data.password
      );
      login(user, { access_token: accessToken, refresh_token: "" });
      navigate("/learn-song-list");
    } catch (error: any) {
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const errorMessage = (responseData as { message: string }).message;
        console.error("Request failed:", errorMessage);
        setError(errorMessage);
      } else {
        console.error("Sign up failed:", error);
      }
    }
  };

  return (
    <div className="w-100">
      <div className="d-flex flex-column">
        {/* Warning */}
        {error && <div style={{ color: "red" }}>{error}</div>}
        <div className="border-bottom p-4 p-md-5">
          <p className="mb-0 text-secondary text-center">Welcome!</p>
          <h1 className="m-0 fs-1 fw-semibold text-center">Register with your e-mail</h1>
        </div>
        <div className="p-4 p-md-5 mx-auto" style={{ maxWidth: "460px", width: "100%" }}>
          <SignUpWithEmailForm continue={onClickSignUpWithEmail} />
        </div>
        <div className="d-flex px-4 px-md-5 gap-4 align-items-center mx-auto" style={{ maxWidth: "460px", width: "100%" }}>
          <div className="col"><hr /></div>
          <div className="text-center"><p className="m-0">or</p></div>
          <div className="col"><hr /></div>
        </div>
        <div className="px-4 px-md-5 pb-3">
          <button
            className="btn bg-dark-light rounded-pill mx-auto my-4 px-2 py-2 d-flex gap-3 align-items-center justify-content-between"
            onClick={onClickSignUpGoogle}
          >
            <span>
              <img src="/images/icons/icon-google-color.svg" alt="" className="" />
            </span>
            Create account with{" "} Google
            <span style={{ width: "32px" }}>&nbsp;</span>
          </button>
        </div>
        {/* 
          <button className="bttn" onClick={onClickSignUpFacebook}>
            <i className="me-2 fa-brands fa-facebook-f"></i> Facebook
          </button> 
        */}
      </div>
      <div className="d-flex flex-column">
        <div className="p-4 p-md-5 text-center border-top">
          <p className="">
            Already have an account?{" "}
          </p>
          <button className="btn btn-warning rounded-pill px-3 py-2" onClick={onLogInTextClick}>
            Log in now
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpStep1;
