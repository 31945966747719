import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { googleLogout } from "@react-oauth/google";
import { useEffect, useState } from "react";
import {
  List,
  Video,
  Sword,
  GameController,
  BookmarkSimple,
  BoxingGlove,
  SignOut,
  UserCircleGear,
  LockOpen,
  UserCirclePlus,
  X,
} from "@phosphor-icons/react";
import OffcanvasButton from "./OffcanvasButton";
import Offcanvas from "./Offcanvas";
import MainMenuItem from "./main_menu/MainMenuItem";

export const Header = () => {
  const { authState, logout } = useAuth();
  const navigate = useNavigate();

  const onHomeClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onLogInClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  const onLogOutClick = useCallback(() => {
    googleLogout();
    logout();
  }, []);

  const onSignUpClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 120);
    });
  }, []);

  return (
    <div className={scroll ? "scrolled" : "not-scrolled"}>
      <header>
        <div className="d-flex justify-content-between align-items-center p-4 px-md-5 border-bottom">
          <div className="d-inline-flex align-items-center">
            <button
              onClick={onHomeClick}
              className="logo text-white d-inline-flex align-items-center border-0 bg-transparent p-0"
            >
              <img
                src="/images/gbc-logos/gbc-icon.svg"
                alt=""
                className="d-block"
              />
              <p className="fs-3 fw-semibold m-0 ps-3">Guitar Battle Club</p>
            </button>
          </div>
          <div>
            <OffcanvasButton
              targetId="mainMenu"
              icon={<List size={32} />}
              className="bg-transparent p-0 border-0 text-white"
            />
          </div>
        </div>
      </header>

      {/* Main menu */}

      <Offcanvas id="mainMenu">
        {authState.isAuthenticated ? (
          <div className="pb-4">
            <ul className="nav flex-column">
              <li>
                <a
                  href=""
                  className="border-bottom d-flex justify-content-end align-items-center gap-3 px-4 py-3 px-md-5 py-md-4 text-yellow text-decoration-none"
                >
                  Upgrade to{" "}
                  <span className="badge rounded-pill text-bg-warning">
                    PRO
                  </span>
                </a>
              </li>
              {/* Admin */}
              {authState.user?.isAdmin && (
                <MainMenuItem
                  text="Admin"
                  icon={<UserCircleGear size={24} />}
                  linkTo={"/admin"}
                />
              )}
              {/* Profile */}
              <MainMenuItem
                text="Profile"
                icon={<UserCircleGear size={24} />}
                linkTo={"/my-profile"}
              />
              {/* Dashboard */}
              <MainMenuItem
                text="Dashboard"
                icon={<GameController size={24} />}
                linkTo={"/my-dashboard"}
              />
              {/* Saved Lessons */}
              <MainMenuItem
                text="Saved Lessons"
                icon={<BookmarkSimple size={24} />}
                borderTop={true}
                linkTo={"/my-lessons"}
              />
              {/* Your Videos */}
              <MainMenuItem
                text="Your Videos"
                icon={<Video size={24} />}
                linkTo={"/my-videos"}
              />
              {/* Your Battles */}
              <MainMenuItem
                text="Your Battles"
                icon={<Sword size={24} />}
                linkTo={"/my-battles"}
              />
              {/* Your Challenges */}
              <MainMenuItem
                text="Your Challenges"
                icon={<BoxingGlove size={24} />}
                linkTo={"/my-challenges"}
              />
              {/* Log out */}
              <MainMenuItem
                text="Log out"
                icon={<SignOut size={24} />}
                borderTop={true}
                onClick={onLogOutClick}
              />
            </ul>
          </div>
        ) : (
          <div className="py-4 py-md-5">
            <ul className="nav flex-column">
              <li>
                <h3 className="px-4 py-3 px-md-5 py-md-4 fw-semibold border-top border-bottom">
                  Seems like you need to <br />
                  login or create an account
                </h3>
              </li>

              {/* Log in */}
              <MainMenuItem
                text="Log in"
                icon={<LockOpen size={24} />}
                onClick={onLogInClick}
              />
              <MainMenuItem
                text="Sign Up"
                icon={<UserCirclePlus size={24} />}
                onClick={onSignUpClick}
              />
            </ul>
          </div>
        )}
      </Offcanvas>
    </div>
  );
};
