import { FacebookLogo, XLogo } from '@phosphor-icons/react';
import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from 'react-share';

interface SocialShareProps {
  url: string;
  title: string;
}

const SocialShare: React.FC<SocialShareProps> = ({ url, title }) => {
  console.log(url, title);
  return (
    <div className="social-share">
      <div className="border-bottom p-4 px-md-5">
        <h3 className="fw-semibold fs-1">
          Share this battle with your friends!
        </h3>
        <p className="m-0 text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad.</p>
      </div>
      <div className="p-4 px-md-5 border-bottom d-flex gap-3 flex-warp justify-content-center">
        <FacebookShareButton url={url} hashtag="#GuitarBattleClub">
          <div className="d-block p-4 border border-warning rounded-3 text-yellow ratio ratio-1x1">
            <FacebookLogo size={32} />
          </div>
        </FacebookShareButton>
        <TwitterShareButton url={url} title={title}>
          <div className="d-block p-4 border border-warning rounded-3 text-yellow ratio ratio-1x1">
            <XLogo size={32} />
          </div>
        </TwitterShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
