import React, { FC, useMemo, useEffect } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import Home from "./views/Home";
import BattlesPage from "./views/battles/BattlesPage";
import SingleBattlePage from "./views/battles/SingleBattlePage";
import LearnSongsListing from "./views/songs/LearnSongsListing";
import LearnSongsSingleLesson from "./views/songs/LearnSongsSingleLesson";
import RecordVideo from "./views/record_video/UploadVideoPage";
import SignUp from "./views/sign_up/SignUp";
import SignIn from "./views/sign_in/SignInPage";
import VerifyEmailContainer from "./views/sign_up/VerifyEmailContainer";
import UnsubscribeEmailContainer from "./views/sign_up/UnsubscribeEmailContainer";
import ResetPasswordContainer from "./views/reset_password/ResetPasswordContainer";
import MyVideos from "./views/my_videos/MyVideosPage";
import MyDashboard from "./views/my_dashboard/MyDashboard";
import MyLessons from "./views/my_lessons/MyLessons";
import MyBattlesPage from "./views/battles/MyBattlesPage";
import MyProfile from "./views/my_profile/MyProfilePage";
import FindOpponent from "./views/find_opponent/FindOpponent";
import PrivacyPolicy from "./views/legal/PrivacyPolicy";
import TermsAndConditions from "./views/legal/TermsAndConditions";
import CookiesPage from "./views/legal/CookiesPage";
import MakeMoney from "./views/MakeMoney";
import Admin from "./views/admin/Admin";
import AdminVideoFeedback from "./views/admin/AdminVideoFeedback";
import { useApiInterceptor } from "./api/config/apiConfig";
import { useAuth } from "./providers/AuthProvider";
import MyChallenges from "./views/my_challenges/MyChallenges";
import DailyLogin from "./utility/DailyLogin";
import NotificationsPage from "./views/notifications/NotificationsPage";
import NotificationDetailPage from "./views/notifications/NotificationDetailPage";
import { MainLayout } from "./views/components/MainLayout";

const AppRouter: FC = () => {
  const { authState } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  useApiInterceptor(useAuth());

  useEffect(() => {
    if (
      authState.isAuthenticated &&
      (location.pathname === "/" || location.pathname === "/home")
    ) {
      // Navigate to learn song list if authenticated and on the home page
      navigate("/home");
    }
  }, [authState.isAuthenticated, location.pathname]);

  const navigationItems = useMemo(
    () => [
      { key: "home", path: "/", element: <Home /> },
      { key: "home-alternate", path: "/home", element: <Home /> },
      { key: "battles", path: "/battles", element: <BattlesPage /> },
      { key: "battles", path: "/battles/active", element: <BattlesPage /> },
      { key: "battles", path: "/battles/finished", element: <BattlesPage /> },
      {
        key: "battles",
        path: "/battles/find-opponents/:videoId?",
        element: <BattlesPage />,
      },
      {
        key: "single-battle",
        path: "/battles/:id",
        element: <SingleBattlePage />,
      },
      { key: "my-videos", path: "/my-videos", element: <MyVideos /> },
      {
        key: "learn-songs",
        path: "/learn-song-list",
        element: <LearnSongsListing />,
      },
      {
        key: "learn-song-play-lesson",
        path: "/learn-song-play-lesson",
        element: <LearnSongsSingleLesson />,
      },
      { key: "upload-video", path: "/upload-video", element: <RecordVideo /> },
      { key: "sign-up", path: "/sign-up", element: <SignUp /> },
      { key: "sign-in", path: "/sign-in", element: <SignIn /> },
      {
        key: "verify-email",
        path: "/verify-email/:token",
        element: <VerifyEmailContainer />,
      },
      {
        key: "unsubscribe",
        path: "/unsubscribe/:token",
        element: <UnsubscribeEmailContainer />,
      },
      {
        key: "reset-password",
        path: "/reset-password/:token",
        element: <ResetPasswordContainer />,
      },
      {
        key: "notifications",
        path: "/notifications",
        element: <NotificationsPage />,
      },
      {
        key: "notifications",
        path: "/notifications/:id",
        element: <NotificationDetailPage />,
      },
      { key: "my-dashboard", path: "/my-dashboard", element: <MyDashboard /> },
      { key: "my-lessons", path: "/my-lessons", element: <MyLessons /> },
      { key: "my-battles", path: "/my-battles", element: <MyBattlesPage /> },
      {
        key: "my-battles",
        path: "/my-battles/active",
        element: <MyBattlesPage />,
      },
      {
        key: "my-battles",
        path: "/my-battles/finished",
        element: <MyBattlesPage />,
      },
      {
        key: "my-battles",
        path: "/my-battles/find-opponents",
        element: <MyBattlesPage />,
      },
      {
        key: "my-challenges",
        path: "/my-challenges/:tab?",
        element: <MyChallenges />,
      },
      { key: "my-profile", path: "/my-profile", element: <MyProfile /> },
      {
        key: "find-opponent",
        path: "/find-opponent",
        element: <FindOpponent />,
      },
      { key: "admin", path: "/admin", element: <Admin /> },
      {
        key: "admin-video-feedback",
        path: "/admin-video-feedback",
        element: <AdminVideoFeedback />,
      },
      {
        key: "privacy-policy",
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        key: "terms-and-conditions",
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        key: "cookies",
        path: "/cookies",
        element: <CookiesPage />,
      },
      {
        key: "make-money",
        path: "/make-money",
        element: <MakeMoney />,
      },
    ],
    []
  );

  DailyLogin();

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {navigationItems.map(({ key, path, element }) => (
          <Route key={key} path={path} element={element} />
        ))}
      </Route>
    </Routes>
  );
};

export default AppRouter;
