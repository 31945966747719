import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { X } from '@phosphor-icons/react';

interface ChallengeSuccessModalProps {
  opponentName: string;
  show: boolean;
  onHide: () => void;
}

const ChallengeSuccessModal: React.FC<ChallengeSuccessModalProps> = ({
  opponentName,
  show,
  onHide,
}) => {
  return (
    <>
      <div className="offcanvas offcanvas-bottom rounded-top-4 border-0">
        <div className="offcanvas-header d-flex justify-content-end align-items-center p-4 px-md-5 border-bottom">
          <button type="button" className="bg-transparent border-0 p-0 text-yellow d-flex align-items-center gap-2 text-uppercase" data-bs-dismiss="offcanvas" aria-label="Close">
            <span><small>Close</small></span>
            <X size={32} />
          </button>
        </div>
        <div className="offcanvas-body p-0">
          <div className="p-4 px-md-5 border-bottom">
            <h3 className="m-0 fs-1 fw-semibold">You've successfully challenged <br /><span className="text-yellow">{opponentName}</span> to battle!</h3>
            <p className="m-0 text-secondary">Waiting for your opponent to accept. In the meantime, challenge others to keep the action going!</p>
          </div>
          <div className="p-4 px-md-5 d-flex w-100 align-items-center justify-content-between">
            <button className="btn rounded-pill btn-outline-warning">Find opponent</button>
            <button className="btn rounded-pill btn-warning">Your challenges</button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>You've successfully challenged <br /><span className="fw-bold">{opponentName}</span> to battle!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="m-0 fs-1 fw-semibold">You've successfully challenged <br /><span className="text-yellow">{opponentName}</span> to battle!</h3>
          <p className="m-0 text-secondary">Waiting for your opponent to accept. In the meantime, challenge others to keep the action going!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChallengeSuccessModal;
