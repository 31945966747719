import React, { useState, useCallback } from "react";
import { getSongPdf } from "../../api/songs";
import { useAuth } from "../../providers/AuthProvider";
import { LearnSongsCardType } from "./LearnSongsCard";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import Accordion from "../components/Accordion";  // Import your Accordion component
import { apiBaseUrl } from "../../api/config/apiConfig";
import { FilePdf } from "@phosphor-icons/react";

interface SongToolsCardProps {
  song: LearnSongsCardType;
}

const SongToolsCard: React.FC<SongToolsCardProps> = ({ song }) => {
  const { authState } = useAuth();
  const { tokens } = authState;
  const tipsVideoThumbnail = `${apiBaseUrl}/videos/thumbnail?filePath=${song.tips ? song.tips.replace(".mp4", "_thumbnail.png") : ""}`;


  const onDownloadTabsClick = useCallback(
    async (pdfUrl?: string) => {
      if (!pdfUrl) {
        console.error("No PDF URL provided");
        return;
      }

      // Open a new window immediately (before any async call)
      const newWindow = window.open("about:blank", "_blank");

      if (!newWindow) {
        console.error("Popup blocked or failed to open window");
        return;
      }

      try {
        const accessToken = tokens?.access_token || "";

        // Start the async process after the window is opened
        const signedUrl = await getSongPdf(accessToken, pdfUrl);

        // Once the async call finishes, update the new window's URL
        newWindow.location.href = signedUrl;
      } catch (error) {
        console.error("Error fetching signed PDF URL:", error);

        // Optionally, close the window if an error occurs
        newWindow.close();
      }
    },
    [tokens]
  );

  // Define the accordion items
  const accordionItems = [
    {
      id: 1,
      title: 'Tabs',
      content: song.pdfUrl ? (
        <div
          onClick={() => onDownloadTabsClick(song.pdfUrl)}
          className="text-yellow text-decoration-none gap-3 d-flex align-items-center"
          role="button"
        >
          Download Tabs <FilePdf size={32} />
        </div>
      ) : (
        <p>No tabs available.</p>
      ),  // JSX.Element
    },
    {
      id: 2,
      title: 'Backing Track',
      content: song.backingTrack ? (
        <AWSVideoPlayerForm
          videoUrl={song.backingTrack}
          isPrivate={true}
        />
      ) : (
        <p className="mb-0 text-secondary"><small>No backing track available.</small></p>
      ),  // JSX.Element
    },
    {
      id: 4,
      title: 'Tips',
      content: song.tips ? (
        <AWSVideoPlayerForm
          videoUrl={song.tips}
          isPrivate={true}
          thumbnailUrl={tipsVideoThumbnail} />
      ) : (
        <p className="mb-0">There are no tips for now!</p>
      ),
    },
  ];

  return (
    <>
      {/* Render the Accordion with dynamic content */}
      <Accordion items={accordionItems} />
    </>
  );
};

export default SongToolsCard;
