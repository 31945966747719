import { FunctionComponent, useCallback, useEffect, useState } from "react";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import SongCardsContainer from "./SongCardsContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { LearnSongsCardType } from "./LearnSongsCard";
import { useAuth } from "../../providers/AuthProvider";
import SongToolsCard from "../songs/SongToolsCard";
import { Disable } from 'react-disable';
import { Helmet } from "react-helmet-async";
import { BookmarkSimple, Dot } from "@phosphor-icons/react";

const LearnSongsSingleLesson: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { song } = location.state;
  // const { authState } = useAuth();
  const { authState, logout } = useAuth();
  const { tokens } = authState;
  const similarSongs: any = [];
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!tokens?.access_token);

  //Get full access after 10s starts
  const [showAccessPrompt, setShowAccessPrompt] = useState<boolean>(false);
  useEffect(() => {
    if (!isLoggedIn) {
      const timer = setTimeout(() => {
        setShowAccessPrompt(true);
      }, 10000); // 10 seconds
      return () => clearTimeout(timer);
    }
  }, [isLoggedIn]);
  //Get full access after 10s ends

  const onLogInTextClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  const onSignUpTextClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  useEffect(() => {
    setIsLoggedIn(!!tokens?.access_token);
  }, [tokens]);

  const onClickSong = useCallback(
    (song: LearnSongsCardType) => {
      navigate("/learn-song-play", { state: { id: song.id } });
    },
    [navigate]
  );

  const onBackClick = () => {
    navigate(-1);
  };

  const onUploadVideoClick = () => {
    navigate("/upload-video", { state: { song: song } });
  };

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>
          {song?.songTitle
            ? `Learn Song - ${song.songTitle} by ${song.artistName}`
            : "Learn Songs"}
        </title>
      </Helmet>



      <>

        <div className="p-4 px-md-5 border-bottom">
          <div className="rounded-4 overflow-hidden position-relative border">
            <AWSVideoPlayerForm
              videoUrl={song.videoUrl}
              thumbnailUrl={song.thumbnailUrl}
              isPrivate={true}
            />
            <div className="p-2 position-absolute top-0 start-0 d-flex justify-content-between align-items-center w-100 bg-gradient-top rounded-top-4">
              <div className="d-flex gap-2">
                <span className="d-flex justify-content-center rounded-3 px-2 py-2 bg-white text-black"><small className="gap-2 d-flex align-items-center text-capitalize">{song?.level}</small></span>
                <span className="d-flex justify-content-center rounded-3 px-2 py-2 bg-white text-black"><small className="gap-2 d-flex align-items-center text-capitalize">{song?.subLevel}</small></span>
              </div>
              <div>
                <BookmarkSimple size={24} />
              </div>
            </div>
            {!isLoggedIn && showAccessPrompt && (
              <div className="p-3 px-md-5 bg-blur rounded-4 position-absolute top-0 start-0 end-0 bottom-0 h-100 d-flex flex-column justify-content-between">
                <p className="text-white lh-sm">
                  Unlock this video and its tabs with a subscription. Subscribe now for full access to
                  lessons and exclusive content.
                </p>
                <button
                  onClick={onSignUpTextClick}
                  className="btn btn-warning rounded-pill w-auto ms-0 me-auto"
                >
                  Get full access
                </button>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center gap-1 my-3 flex-wrap">
            <p className="m-0 fw-semibold text-truncate lh-sm">
              <small>{song?.artistName}</small>
            </p>
            <Dot size={12} />
            <p className="m-0 text-secondary text-truncate lh-sm">
              <small>{song?.songTitle}</small>
            </p>
          </div>
          {authState.isAuthenticated && (
            <div>
              <button className="bg-transparent text-yellow border-0 p-0">
                <small>
                  Save lesson <BookmarkSimple size={24} />
                </small>
              </button>
            </div>
          )}
        </div>

        {/* <Disable disabled={!isLoggedIn}> */}
        {authState.isAuthenticated && (
          <>
            {/* <div className="p-4 px-md-5 border-bottom w-100">
              <h2 className="fw-semibold">Want to battle others?</h2>
              <p className="text-secondary">
                Record and upload a video, and after feedback you can find opponents to battle.
              </p>
              <button
                onClick={onUploadVideoClick}
                className="btn btn-warning rounded-pill w-auto"
              >
                {song?.hasUserVideo ? "Upload video" : "Upload video"}
              </button>
            </div> */}
            <SongToolsCard song={song} />
          </>
        )}

        {/* </Disable> */}

      </>
    </>
  );
};

export default LearnSongsSingleLesson;
