import api, { apiBaseUrl } from "./config/apiConfig";

export const awardDailyLoginXP = async (xp: number): Promise<void> => {
    try {
        await api.post(`${apiBaseUrl}/xp/daily-login`, {
            xp
        });
    } catch (error) {
        console.error("Award XP failed:", error);
    }
}