import { FunctionComponent } from "react";
import { User } from "../../../models/User";
import { Sword, Trophy, Heart, Dot } from "@phosphor-icons/react";
import { UserStats } from "../../../models/UserStats";
import UserBadgesSmallDisplay from "./UserBadgesSmallDisplay";

export enum PlayerStatus {
  WINNER = "WINNER",
  TIE = "TIE",
  LOSER = "LOSER",
}

export type PlayerSongCardProps = {
  user: User;
  userStats: UserStats;
  likes: number;
  playerStatus?: PlayerStatus;
  video?: {
    song: {
      artistName: string;
      songTitle: string;
    };
  };
  tabType: string;
};

const PlayerSongCard: FunctionComponent<PlayerSongCardProps> = ({
  user,
  userStats,
  likes,
  playerStatus,
  video,
  tabType,
}) => {
  return (
    <div>
      <div className="d-flex align-items-center gap-3">
        <div>
          <img
            width="40"
            height="40"
            className="rounded-3 img-fluid"
            crossOrigin="anonymous"
            alt={user?.firstName || ""}
            src={user?.pictureUrl || "/profile_placeholder.png"}
          />
        </div>
        <div>
          <h3 className="mb-1 fs-6 fw-medium">
            <small>
              {user?.firstName} {user?.lastName}
            </small>
          </h3>

          <div className="user-info d-flex align-items-center gap-2">
            {/* Show badges if user has any  */}
            <UserBadgesSmallDisplay badges={userStats?.badges} />
            <span>
              <small className="d-flex align-items-center text-yellow gap-1">
                <Sword size={18} /> 128 / 839
              </small>
            </span>
            <span>
              <small className="d-flex align-items-center text-yellow gap-1">
                <Trophy size={18} /> 29 / 756
              </small>
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <div className="d-flex align-items-center gap-1">
          <p className="m-0 fw-semibold text-truncate">
            <small>{video?.song?.artistName || ""}</small>
          </p>
          <Dot size={12} />
          <p className="m-0 text-secondary text-truncate">
            <small>{video?.song?.songTitle || ""}</small>
          </p>
        </div>
        <div>
          {likes >= 0 && (
            <span>
              {tabType === "FINISHED" &&
              (playerStatus == "WINNER" || playerStatus == "TIE") ? (
                <small className="rounded-pill bg-secondary text-black fw-light d-flex align-items-center gap-1 px-2 py-1">
                  {playerStatus == "TIE" ? "Tie" : "Winner"} <Heart size={14} />{" "}
                  {likes}
                </small>
              ) : (
                <small className="rounded-pill bg-secondary text-black fw-light d-flex align-items-center gap-1 px-2 py-1">
                  <Heart size={14} /> {likes}
                </small>
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerSongCard;
