import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Import useParams
import { useAuth } from "../../providers/AuthProvider";
import {
  acceptChallenge,
  cancelChallenge,
  getMyChallengesSent,
  getMyChallengesReceived,
} from "../../api/challenges";
import ChallengeCard from "./ChallengeCard";
import { Helmet } from "react-helmet-async";

const MyChallenges: FunctionComponent = () => {
  const navigate = useNavigate();
  const { tab } = useParams<{ tab?: string }>(); // Use useParams to get tab
  const { authState } = useAuth();
  const { tokens } = authState;
  const [challengesSent, setChallengesSent] = useState<any[]>([]);
  const [challengesReceived, setChallengesReceived] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [selectedTab, setSelectedTab] = useState("SENT"); // Default to SENT

  // Set selected tab based on URL parameter
  useEffect(() => {
    if (tab === "received") {
      setSelectedTab("RECEIVED");
    } else {
      setSelectedTab("SENT"); // Default to SENT if tab is undefined or "sent"
    }
  }, [tab]);

  const fetchChallenges = async () => {
    try {
      const accessToken = tokens?.access_token || "";
      const sentChallenges: any[] = await getMyChallengesSent(accessToken);
      const receivedChallenges: any[] = await getMyChallengesReceived(
        accessToken
      );
      setChallengesSent(sentChallenges);
      setChallengesReceived(receivedChallenges);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(
        error instanceof Error ? error : new Error("Failed to fetch challenges")
      );
    }
  };

  useEffect(() => {
    fetchChallenges();
  }, []);

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    navigate(`/my-challenges/${tab.toLowerCase()}`);
  };

  const onCancelChallengeClick = async (battleId: number) => {
    const accessToken = tokens?.access_token || "";
    await cancelChallenge(accessToken, battleId);
    fetchChallenges();
  };

  const onAcceptChallengeClick = async (battleId: number) => {
    const accessToken = tokens?.access_token || "";
    await acceptChallenge(accessToken, battleId);
    fetchChallenges();
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 275);
    });
  }, []);

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"My challenges"}</title>
      </Helmet>
      <div className="p-4 px-md-5 w-100 pb-0">
        <h2 className="fw-semibold fs-1">Your Challenges</h2>
        <p className="m-0 text-secondary">View and manage all your sent and received challenges in one place.</p>
      </div>
      <div className="w-100 p-4 px-md-5 border-bottom">
        <div className="d-flex w-100 justify-content-between flex-wrap gap-2">
          <button
            className={` ${selectedTab === "SENT"
              ? "col btn btn-light rounded-pill"
              : "col btn btn-outline-light rounded-pill"
              }`}
            onClick={() => handleTabChange("SENT")}
          >
            Sent
          </button>
          <button
            className={` ${selectedTab === "RECEIVED"
              ? "col btn btn-light rounded-pill"
              : "col btn btn-outline-light rounded-pill"
              }`}
            onClick={() => handleTabChange("RECEIVED")}
          >
            Received
          </button>
        </div>
      </div>

      <div className="p-4 px-md-5 w-100">
        {loading && (
          <div className="p-4 px-md-5 text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {selectedTab === "SENT" && challengesSent.length === 0 && (
          <div className="p-4 px-md-5 text-center">
            <p>No sent challenges yet</p>
          </div>
        )}
        {selectedTab === "RECEIVED" && challengesReceived.length === 0 && (
          <div className="p-4 px-md-5 text-center">
            <p>No received challenges yet.</p>
          </div>
        )}
        <div className="row">
          {selectedTab === "SENT" &&
            challengesSent.map((sentChallenge) => (
              <ChallengeCard
                key={sentChallenge.id}
                challenge={sentChallenge}
                type="SENT"
                onCancelChallengeClick={onCancelChallengeClick}
                onAcceptChallengeClick={() => { }}
              />
            ))}
          {selectedTab === "RECEIVED" &&
            challengesReceived.map((receivedChallenge) => (
              <ChallengeCard
                key={receivedChallenge.id}
                challenge={receivedChallenge}
                type="RECEIVED"
                onCancelChallengeClick={() => { }}
                onAcceptChallengeClick={onAcceptChallengeClick}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default MyChallenges;
