import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import React, { useState, useEffect } from "react";
import { LockSimple, Trophy } from "@phosphor-icons/react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Accordion from "../components/Accordion";
import { getUserStats } from "../../api/users";
import { UserStats } from "../../models/UserStats";
import { Badge } from "react-bootstrap";
import { getBadgeImage } from "../../utility/BadgeUtility";

const MyDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { authState } = useAuth();
  const [userStats, setUserStats] = useState<UserStats | null>(null);
  const [showCompleteProfile, setShowCompleteProfile] = useState(false);

  useEffect(() => {
    fetchUserStats();
  }, [authState.user]);

  //TODO refactor into a hook as AuthProvider
  const fetchUserStats = async () => {
    if (authState.user) {
      try {
        const stats = await getUserStats(authState.user?.id);
        setUserStats(stats);
        var showProfile =
          stats.badges.filter(
            (badge) => badge.name === "GBC Member" && badge.obtained === false
          ).length > 0;
        setShowCompleteProfile(showProfile);
      } catch (error) {
        new Error("Failed to fetch user stats");
      }
    }
  };

  const onClickMyVideos = useCallback(() => {
    navigate("/my-videos");
  }, [navigate]);

  const onClickMyLessons = useCallback(() => {
    navigate("/my-lessons");
  }, [navigate]);

  const onClickMyBattles = useCallback(() => {
    navigate("/my-battles");
  }, [navigate]);

  // const [scroll, setScroll] = useState(false);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScroll(window.scrollY > 275);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   // Cleanup event listener on component unmount
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  const onProfileClick = useCallback(() => {
    navigate("/my-profile");
  }, [navigate]);

  // Define the accordion items
  const accordionItems = [
    {
      id: 1,
      title: "Tournament 01",
      content: (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
              <p className="mb-0 text-secondary">
                <small>Date</small>
              </p>
              <p className="mb-0 fw-semibold">November 8, 2024</p>
            </div>
            <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
              <p className="mb-0 text-secondary">
                <small>Entry Fee</small>
              </p>
              <p className="mb-0 fw-semibold">2000 Points</p>
            </div>
          </div>
          <div className="mt-3">
            <p className="fw-normal">
              <small>
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content. Lorem ipsum
                may be used as a placeholder before the final copy is available.
              </small>
            </p>
          </div>
          <button className="text-warning bg-transparent fw-normal border-0 p-0 mb-1 d-flex gap-2 align-items-center">
            <small>Join Tournament</small> <Trophy size={20} />
          </button>
        </>
      ),
    },
    {
      id: 2,
      title: "Tournament 02",
      content: (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
              <p className="mb-0 text-secondary">
                <small>Date</small>
              </p>
              <p className="mb-0 fw-semibold">November 8, 2024</p>
            </div>
            <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
              <p className="mb-0 text-secondary">
                <small>Entry Fee</small>
              </p>
              <p className="mb-0 fw-semibold">2000 Points</p>
            </div>
          </div>
          <div className="mt-3">
            <p className="fw-normal">
              <small>
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content. Lorem ipsum
                may be used as a placeholder before the final copy is available.
              </small>
            </p>
          </div>
          <button className="text-warning bg-transparent fw-normal border-0 p-0 mb-1 d-flex gap-2 align-items-center">
            <small>Join Tournament</small> <Trophy size={20} />
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="p-4 px-md-5 w-100 border-bottom">
        <h2 className="fw-semibold fs-1">Dashboard</h2>
        <p className="m-0 text-secondary">
          Manage your informations, complete all fields to receive a Club Member
          Badge.
        </p>
      </div>
      {showCompleteProfile && (
        <div className="d-flex flex-column gap-3 p-4 px-md-5 border-bottom">
          <p className="mb-0 text-secondary">
            Complete your profile to be able to do something lorem ipsum
            dolorset.
          </p>
          <button
            onClick={onProfileClick}
            className="btn btn-warning rounded-pill w-auto ms-0 px-4 py-2 mx-auto mb-1"
          >
            Complete your profile
          </button>
        </div>
      )}
      <Accordion items={accordionItems} />
      <div className="p-4 px-md-5 w-100 border-bottom">
        <h2 className="fw-semibold fs-1">Game Badges</h2>
        <p className="m-0 text-secondary">
          Manage your informations, complete all fields to receive a Club Member
          Badge.
        </p>
      </div>
      <div className="p-4 px-md-5">
        {userStats?.badges.map((badge) => (
          <div
            key={badge.id}
            className="bg-dark-light rounded-4 p-1 d-flex gap-3 mb-2"
          >
            <div className="position-relative d-flex col-5">
              <img
                key={badge.id}
                src={getBadgeImage(badge.name)}
                alt={badge.name}
                className="img-fluid ratio ratio-1x1 rounded-4"
              />
              {/* <img src="../images/badges/badge-fretboard-freshman.png" alt="" className="img-fluid ratio ratio-1x1 rounded-4" /> */}
              {badge.obtained ? (
                <div className="position-absolute bottom-0 left-0 p-2 d-flex">
                  <p className="m-0 text-warning bg-blur px-3 py-1 rounded-3">
                    <small>{badge.count}</small>
                  </p>
                </div>
              ) : (
                <div className="position-absolute top-0 right-0 bottom-0 left-0 rounded-4 overflow-hidden h-100 w-100 flex-grow-1">
                  <p className="m-0 text-warning bg-blur px-3 py-1 rounded-3 h-100 w-100 d-flex align-items-center justify-content-center">
                    <LockSimple size={20} />
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex flex-column mh-100 justify-content-between py-2">
              <div>
                <h3 className="fs-5 m-0 fw-medium text-warning">
                  {badge.name}
                </h3>
                <p className="m-0 lh-sm mb-2">
                  <small>{badge.description}</small>
                </p>
              </div>
              <div>
                <p className="m-0 text-secondary">
                  <small>+200 Points needed</small>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="w-100">
        <Tabs>
          <TabList>
            <Tab>How to get Points</Tab>
            <Tab>Where to use Points</Tab>
          </TabList>

          <TabPanel>
            <div className="p-4 px-md-5 bg-dark-light w-100">
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Create account</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>200 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Subscription payment</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>200 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Complete profile</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>50 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Mark lesson as learned</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>15 XP</small>
                </p>
              </div>
              <p className="fw-semibold mt-4 mb-0 fs-5">Daily login</p>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>First login of the series</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>5 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Login on Days 2, 3, 4 of the series</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>10 XP/Day</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Daily login after Day 5</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>20 XP/Day</small>
                </p>
              </div>
              <p className="fw-semibold mt-4 mb-0 fs-5">Upload video</p>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Rhythm</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>30 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Intonation</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>25 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Originality</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>20 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between mb-2">
                <p className="text-secondary text-truncate mb-0">
                  <small>Improvisation</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>20 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Accept challenge</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>50 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Participate in battle</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>100 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Win a battle</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>300 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Win a tournament</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>5000 XP</small>
                </p>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="p-4 px-md-5 bg-dark-light w-100">
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Challenge to battle</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>50 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>Register for a tournament</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>2000 XP</small>
                </p>
              </div>
              <div className="d-flex w-100 flex-wrap justify-content-between">
                <p className="text-secondary text-truncate mb-0">
                  <small>30-minute concert</small>
                </p>
                <p className="mb-0 fw-semibold">
                  <small>15000 XP</small>
                </p>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default MyDashboard;
